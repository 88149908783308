import React from "react";
import "./hero.css";
import Navbar from "../nav/nav-bar";
import CountdownTimer from "../countdown/countdown";
import HeroComp from "./heroComp";

const Hero = () => {
  return (
    <div id="hero" className="hero-bg">
      <Navbar />
      <CountdownTimer />
      <HeroComp />
    </div>
  );
};

export default Hero;
