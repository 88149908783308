import React from 'react';
import Hero from '../components/hero/hero';
import Signup from '../components/sign-up/sign-up';

const Landing = () => {
  return (
    <div>
        <Hero />
        <Signup />
    </div>
  )
}

export default Landing