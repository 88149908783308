import React, { useEffect, useState } from "react";
import "./nav.css";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";

const Navbar = () => {

  const { t, i18n } = useTranslation();

  const [active, setActive] = useState(t("home"));
  
  
  const clickNav = (e) => {
    setActive(e.target.textContent);
  };

  // Function to change the language
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("i18nextLng", event.target.value);
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedText = selectedOption.textContent; 
    localStorage.setItem('langSelect', selectedText);
  };
  

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
        i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage(localStorage.getItem("i18nextLng"));
    }
    setActive(t("home"));
    // eslint-disable-next-line
}, [localStorage.getItem("i18nextLng")]);

  return (
    <>
        <select
          value={localStorage.getItem("i18nextLng")}
          onChange={handleLanguageChange}
          className="lang-select"
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
          <option value="pt">Português</option>
        </select>
      <div className="nav-cont">
        <div className="nav-left">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav-right">
          <ul>
            <a href="#hero" style={{ textDecoration: "none" }}>
              <li
                onClick={(e) => clickNav(e)}
                className={active === `${t("home")}` ? `active-style` : undefined}
              >
                {t("home")}
              </li>
            </a>
            <a href="#sign-up" style={{ textDecoration: "none" }}>
              <li
                onClick={(e) => clickNav(e)}
                className={active === `${t("signup")}` ? `active-style` : undefined}
              >
                {t("signup")}
              </li>
            </a>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
