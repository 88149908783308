import React, { useEffect, useMemo, useState } from "react";
import "./sign-up.css";
import {
  Btn,
  FormInput,
  isEveryPropertyFull,
  SuccessAlert,
  validateEmail,
  validatePhoneNumber,
} from "../form-comp/form-component";
import { AxiosPost } from "../../api/axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useTranslation } from "react-i18next";

const Signup = () => {

  const { t } = useTranslation();
  const langSelect = localStorage.getItem("langSelect");

  const url = "wait-list/";
  const [phone, setPhone] = useState("");

  const [data, setData] = useState({
    name: "",
    email: "",
  });
  const [dataErr, setDataErr] = useState({
    email: "",
    phoneNumber: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleData = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    if (e.target.id === "email") {
      const emailError = validateEmail(e.target.value)
        ? ""
        : t("emailErr");
      setDataErr({ ...dataErr, email: emailError });
    }
    if (data.email && data.phoneNumber && data.name) {
      setError("");
    }
  };
  const dataObj = useMemo(() => {
    return {
      name: data.name,
      phoneNumber: phone,
      email: data.email,
      language: langSelect === "English" ? "English" : langSelect === "Français" ? "French" : langSelect === "Português" ? "Portuguese" : !langSelect ? "English" : "English"

    };
  }, [data.name, data.email, phone, langSelect]);
  // console.log(dataObj);
  // console.log(isEveryPropertyFull(dataObj));
  useEffect(() => {
    if(isEveryPropertyFull(dataObj)){
      setError("")
    }
    // eslint-disable-next-line
  }, [dataObj])
  
  
  const submitForm = () => {
    if (
      !data?.name || !validatePhoneNumber(phone) ||
      !data?.email ||
      dataErr.phoneNumber ||
      dataErr.email
    ) {
      setError(t("allErr"));
    } else {
      setIsLoading(true);
      AxiosPost(url, dataObj)
        .then((res) => {
          // console.log(res);
          setIsLoading(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          console.error(err.response);
          for (let i = 0; i < err?.response?.data?.errors?.length; i++) {
            const fieldName = err?.response?.data?.errors[i]?.fieldName;
            const error = err?.response?.data?.errors[i]?.error;
            setDataErr((prevDataErr) => ({
              ...prevDataErr,
              [fieldName]: error,
            }));
          }
          setIsLoading(false);
        });
    }
  };
  

  return (
    <div id="sign-up" className="signup-cont">
      <SuccessAlert
        message={t("success")}
        success={isSuccess}
        setSuccess={setIsSuccess}
      />
      <h2>{t("signup")}</h2>
      <p>
      {t("joinUsP")}
      </p>
      <div className="signup-form">
        <FormInput
          inputLabel={t("name")}
          type="text"
          id="name"
          onChange={(e) => handleData(e)}
        />
        {/* <FormInput
          inputLabel={"enter phone number:"}
          type="tel"
          id="phoneNumber"
          onChange={(e) => handleData(e)}
          err={dataErr.phoneNumber}
        /> */}
        <div className="inter-phone_div">
          <h4>{t("phone")}</h4>
          <PhoneInput
            defaultCountry="ng"
            value={phone}
            onChange={(phone) => {setPhone(phone); setDataErr({...dataErr, phoneNumber: ""})}}
            inputClassName="inter-phone"
          />
          {dataErr?.phoneNumber && <p style={{color: "red", fontSize: "12px", textAlign: "left",width: "100%"}}>{dataErr?.phoneNumber}</p>}
        </div>
        <FormInput
          inputLabel={t("email")}
          type={"email"}
          id="email"
          onChange={(e) => handleData(e)}
          err={dataErr.email}
        />
        <div className="signup-btn">
          <Btn
            btnText={t("signup")}
            btnClass={"btn"}
            btnClick={submitForm}
            loading={isLoading}
            disabled={
              (!data?.name || !validatePhoneNumber(phone) ||
                !data?.email ||
                dataErr.phoneNumber ||
                dataErr.email) &&
              true
            }
            err={error}
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
