// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


// Get the persisted language from localStorage or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

// Define translations
const resources = {
    en: {
        translation: {
            home: "Home",
            signup: "Sign up",
            countdownT: "Coming in:",
            days: "Days",
            hours: "Hours",
            minutes: "Minutes",
            seconds: "Seconds",   
            heroP: "Join our wait list today and enjoy",
            heroSpan1: "A Free Movie",
            heroP4: "on us!",
            heroP2: "This offer is limited to the first",
            heroP3: "users. don’t miss out!",
            joinUsP: "Join us today! and unlock exclusive benefits, rewards, and more. Let’s get started",
            name: "enter full name:",
            phone: "enter phone number:",
            email: "enter email address:",
            emailErr: "Invalid email address.",
            allErr: "Please fill all available fields",
            success: "Congratulations!!! you’ve successfully signed up"
        },
    },
    pt: {
        translation: {
            home: "Lar",
            signup: "Cadastre-se",
            countdownT: "Entrando:",
            days: "Dias",
            hours: "horas",
            minutes: "minutos",
            seconds: "segundos",   
            heroP: "Junte-se hoje à nossa lista de espera e desfrute", 
            heroSpan1: "um filme gratuito",
            heroP4: "por nossa conta!",
            heroP2: " Esta oferta está limitada aos primeiros",
            heroP3: "utilizadores. Não perca!",
            joinUsP: "Junte-se a nós hoje! e desbloqueie benefícios, recompensas exclusivas e muito mais. Vamos começar",
            name: "digite o nome completo:",
            phone: "digite o número do telefone:",
            email: "digite o endereço de e-mail:",
            emailErr: "Endereço de e-mail inválido.",
            allErr: "Por favor preencha todos os campos disponíveis",
            success: "Parabéns!!! você se inscreveu com sucesso"
        },
    },
    fr: {
        translation: {
            home: "Maison",
            signup: "S'inscrire",
            countdownT: "À venir:",
            days: "Jours",
            hours: "heures",
            minutes: "minutes",
            seconds: "secondes",  
            heroP: "Rejoignez notre liste d'attente dès aujourd'hui et profitez",
            heroSpan1: "film gratuit offert",
            heroP4: "par nos soins!",
            heroP2: "Cette offre est limitée aux ",
            heroP3: "premiers utilisateurs, ne la ratez pas !",
            joinUsP: "Rejoignez-nous dès aujourd'hui ! et bénéficiez d'avantages exclusifs, de récompenses et bien plus encore. Commençons",
            name: "entrez le nom complet :",
            phone: "entrez le numéro de téléphone :",
            email: "entrez l'adresse e-mail :",
            emailErr: "Adresse e-mail invalide.",
            allErr: "Veuillez remplir tous les champs disponibles",
            success: "Félicitations!!! vous êtes inscrit avec succès"
        },
    },
};

// Initialize i18next
i18n.use(initReactI18next).init({
    resources,
    lng: savedLanguage, // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
        escapeValue: false, // React already escapes
    },
});

export default i18n;
