
import Landing from "./pages/landing";
import { Suspense} from "react";

function App() {

  return (
    <Suspense fallback={null}>
      <div>
        <Landing />
      </div>
    </Suspense>
  );
}

export default App;
